/* eslint-disable react/no-unknown-property */
'use client';
import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Link from 'next/link';
import { ContactInfo } from '../../types/CommonTypes';
import { Text } from '../Typography';

interface ContactProps {
  className: string;
  info: ContactInfo;
}

const Contact = (props: ContactProps) => {
  const { className, info } = props;
  const { address, email, phone } = info;
  const { t } = useTranslation('navigation');
  const [isCVRCopied, setIsCVRCopied] = React.useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  return (
    <div className={className}>
      <article className="relative flex flex-col gap-y-1.5 pb-4">
        <Link
          href="https://maps.app.goo.gl/e5jkdYPKnsx7M8kG7"
          className="flex items-center"
          target="_blank"
          rel="noreferrer"
          aria-label={t('map_location_aria_label') + ` ${address}`}
        >
          <Text variant="large" className="min-w-20">
            {t('address_label')}
          </Text>
          <span className="text-gray-250 text-lg">{address}</span>
        </Link>
        <Link
          href={`mailto:${email}`}
          className="flex items-center"
          aria-label={t('send_email_aria_label') + ` ${email}`}
        >
          <Text variant="large" className="min-w-20">
            E-mail
          </Text>
          <span className="text-gray-250 text-lg">{email}</span>
        </Link>
        <Link
          href={`tel:${phone.replace(' ', '')}`}
          className="flex items-center"
          aria-label={t('call_us_aria_label') + ` ${phone}`}
        >
          <Text variant="large" className="min-w-20">
            {t('phone_label')}
          </Text>
          <span className="text-gray-250 text-lg">{phone}</span>
        </Link>
        <button
          onClick={() => {
            if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current);
            }

            navigator.clipboard.writeText('40965874');
            setIsCVRCopied(true);

            const callback = () => {
              if (typeof setIsCVRCopied === 'function') {
                setIsCVRCopied(false);
              }
            };

            timeoutRef.current = setTimeout(callback, 1800);
          }}
          className="flex"
        >
          <Text variant="large" className="min-w-20 text-start">
            CVR
          </Text>
          <span className="text-gray-250 text-lg">40965874</span>
        </button>
        {isCVRCopied && (
          <Text className="animate-fadeInOut absolute top-full -mt-2.5 w-fit rounded-full bg-gray-500 px-2 py-0.5">
            {t('copied_text')}
          </Text>
        )}
      </article>
      <style jsx>{`
        @keyframes fadeInOut {
          0% {
            opacity: 0;
          }
          10% {
            opacity: 1;
          }
          90% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .animate-fadeInOut {
          animation: fadeInOut 1.8s ease-in forwards;
        }
      `}</style>
    </div>
  );
};

export default Contact;
